




























import {vxm} from "@/store";
import {Component, Vue} from 'vue-property-decorator';
import ErpcDashCaseView from "@/views/viewcomponents/ErpcDashCaseView.vue";

import WaitModal from "@/views/widgets/modals/WaitModal.vue";


import {Configuration, UserAdminApiFactory, UserDTO} from "@shared_vue/openapi/userapi";

import {ServiceBusClient, ServiceBusReceiver} from "@azure/service-bus";
import UserDetailView from "@/views/viewcomponents/UserDetailView.vue";

@Component({
  components: {UserDetailView, ErpcDashCaseView, WaitModal}
})

export default class UsersPage extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private showWait: boolean = false;
  private showNew: boolean = false;
  private newCat: any = 0;
  private adminApi = UserAdminApiFactory(<Configuration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);
  private userList : UserDTO[] = []
  private newTitle : string = '';
  private newDescription : string = '';
  private currentUser: UserDTO = <UserDTO>{};
  private waitText: string = "Loading..."
  private catOptions = [
    {value:'Murder', label:'Murder'}
  ]

  private okToView: boolean = false;

  mounted() {
    this.fetchAll();
  }


  

  private showUser(guid: string){
    console.log('showuserclick')
    this.currentUser=this.userList.filter(s=>s.guid==guid)[0]; //eyyy
    this.okToView=true;
  }

  

  private fetchAll(){
    this.waitText="Loading..."
    this.showWait=true;
    this.adminApi.userAdminListAllUsers().then(resp => {
      console.log(resp.data);
      //add version stuff here :-O
      this.userList=resp.data;
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      this.showWait=false;
    });
  }

  

  public dismiss(){
    this.showNew=false;
    this.showWait=false;
  }

 

}
