














import {Component, Prop, Vue} from "vue-property-decorator";
import DeskRequest from "@/views/viewcomponents/DeskRequest.vue";
import {UserDTO} from "@shared_vue/openapi/userapi";

@Component({
  components: {DeskRequest}
})

export default class ErpcDashCaseView extends Vue {
  @Prop({default: () => []}) readonly users!: UserDTO[];

  private emitUp(id: any){
    this.$emit('showThread',id)
  }
}
