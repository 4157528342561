















































import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator';


import {vxm} from "@/store";
import {PermissionDTO, UserAdminApiFactory, UserDTO} from "@shared_vue/openapi/userapi/api";
import VueJsonEditorTS from "@/views/viewcomponents/VueJsonEditorTS.vue";
import {Configuration} from "@shared_vue/openapi/userapi";


@Component({
components: { VueJsonEditorTS }
})

export default class UserDetailView extends Vue {

  @PropSync('user', { default: undefined }) syncedUser: UserDTO | undefined;

  private adminApi = UserAdminApiFactory(<Configuration>{basePath:process.env.VUE_APP_USER_API_URL},process.env.VUE_APP_USER_API_URL);
  private decodedPerms: string = "";
  
  mounted() {
    console.log('mounted')
    if (this.syncedUser){
      this.decodedPerms = JSON.parse(UserDetailView.b64_to_utf8(this.syncedUser.base64Permissions));
    }
  }

  @Watch('syncedUser')
  onValChange(val: any, oldval: any) {
    if (this.syncedUser) {
      this.decodedPerms = JSON.parse(UserDetailView.b64_to_utf8(this.syncedUser.base64Permissions));
    }
  }
  

  private static utf8_to_b64( str: string ) {
    return window.btoa(unescape(encodeURIComponent( str )));
  }
  
  private static b64_to_utf8(str: string ) {
    if (str)
      return decodeURIComponent(escape(window.atob( str )));
    else 
      return '{}'
  }
  

  private async deleteUser(){
    console.log('going to delete: ', this.syncedUser!.guid)
    let res = await this.adminApi.userAdminDeleteUser(this.syncedUser!.guid);
    console.log('deleted response: ', res)
  }
  
  private onJsonChange (value: any) {
    console.log('change value:', value)
  }
  
  private async onJsonSave (value: any) {
    console.log('save value:', value);
    let b64_version = UserDetailView.utf8_to_b64(JSON.stringify(value));
    // send to server. Probably need "are you sure"
    let permDto = <PermissionDTO>{ guid: (<UserDTO>this.syncedUser).guid,
                                    ban: false,
                                    base64Perm: b64_version}
    let result = await this.adminApi.userAdminSavePermissions(permDto);
    console.log('save result:', result)
  }

  private onError (value: any) {
    console.log('error value:', value);
  }
}
