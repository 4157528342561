














import "@/assets/jsoneditor.css";
import JsonEditor from "@/assets/jsoneditor";

import {Component, Prop, PropSync, Vue, Watch} from 'vue-property-decorator';




@Component({
  components: {  }
})

export default class VueJsonEditorTS extends Vue {
  @Prop({default: 'true'}) showBtns : boolean | undefined;
  @Prop({default: 'true'}) expandedOnStart : boolean | undefined;
  //@Prop({default: null}) value : any | undefined;
  @PropSync('value', { default: null }) valu: any | undefined;
  @Prop({default: 'tree'}) mode : string | undefined;
  @Prop({default: 'en'}) lang : string | undefined;
  @Prop({default: ()=>["tree", "code", "form", "text", "view"]}) modes : Array<string> | undefined;
  private editor: any = null;
  private error: boolean = false;
  get json() {
    return this.valu;
  }
  set json(val: any){
    this.valu = val;
  }
  private internalChange: boolean = false;
  private expandedModes: Array<string> = ["tree", "view", "form"];
  private locale = {
    it: {
      save: "SALVA"
    },
    en: {
      save: "SAVE"
    },
    zh: {
      save: "保存"
    }
  };
  
  @Watch('value')
  onValChange(val: any, oldval: any){
    if (!this.internalChange) {
      this.setEditor(val);

      this.error = false;
      this.expandAll();
    }
  }
  
  
  
  mounted() {
    let self = this;

    let options = {
      mode: this.mode,
      modes: this.modes, // allowed modes
      onChange() {
        try {
          let json = self.editor.get();
          self.json = json;
          self.error = false;
          self.$emit("json-change", json);
          self.internalChange = true;
          self.$emit("input", json);
          self.$nextTick(function() {
            self.internalChange = false;
          });
        } catch (e) {
          self.error = true;
          self.$emit("has-error", e);
        }
      },
      onModeChange() {
        self.expandAll();
      }
    };

    this.editor = new JsonEditor(
        <Element>this.$el.querySelector(".jsoneditor-vue"),
        options,
        this.json
    );
  }
  
 
  private expandAll() {
      if (
          this.expandedOnStart &&
          this.expandedModes.includes(this.editor.getMode())
      ) {
        this.editor.expandAll();
      }
    }

    private onSave() {
      this.$emit("json-save", this.json);
    }

    private async setEditor(value: any) {
      if (this.editor) this.editor.set(value);
    }
  
};
